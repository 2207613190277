import classNames from "classnames"
import React, { ReactNode, useCallback, useMemo } from "react"

import { Color } from "../../../../constants/V2/color"
import { trackSegmentEvent } from "../../../../utils/analytics"
import Link from "../../Link"
import Typography, { TypographySize } from "../Typography"

import {
  ACCELERATE_EXPERIMENT_EVENT_NAME,
  PLANS_EXPERIMENT_EVENT_NAME,
} from "@constants/segmentEvents"
import { getTextColorClass } from "@utils/V2/color"

type TextButtonProps = {
  text: string
  mobileText?: string
  textSize?: TypographySize
  style: "icon" | "underlined" | "text"
  weight?: "book" | "medium"
  color: Color
  linkUrl?: string
  mobileLinkUrl?: string
  icon?: ReactNode
  disabled?: boolean
  onClick?: () => void
  trackingEvent?: string
  trackingEventKey?: string
  trackingEventValue?: string
  className?: string
  uppercase?: boolean
}

const TextButton = ({
  text,
  icon,
  color,
  style,
  weight = "medium",
  onClick,
  linkUrl,
  mobileText,
  mobileLinkUrl,
  textSize = style === "text" ? "subscript-lg" : "body-lg",
  trackingEvent,
  trackingEventKey,
  disabled = false,
  trackingEventValue,
  uppercase = style === "text" ? true : false,
  className,
}: TextButtonProps) => {
  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLElement>, link?: string) => {
      if (trackingEvent) {
        // this is to support custom plans tracking event
        // TODO rework this to be more generic in future

        let event: Record<string, string> | undefined
        if (
          trackingEvent === ACCELERATE_EXPERIMENT_EVENT_NAME ||
          trackingEvent === PLANS_EXPERIMENT_EVENT_NAME
        ) {
          const urlParams = new URLSearchParams(window.location.search)
          const buuidValue = urlParams.get("buuid") || ""
          const vValue = urlParams.get("v") || ""
          const pValue = urlParams.get("p") || ""
          event = { ["buuid"]: buuidValue, ["v"]: vValue, ["p"]: pValue }
        } else {
          event =
            trackingEventKey && trackingEventValue
              ? { [trackingEventKey]: trackingEventValue }
              : undefined
        }

        trackSegmentEvent(trackingEvent, event)
      }

      if (onClick) {
        onClick()
      } else if (link && link.startsWith("#")) {
        e.preventDefault()
        const [scrollId, clickId] = link.includes("@")
          ? link.split("@")
          : [link, null]

        if (clickId) {
          document.getElementById(clickId)?.click()
        }

        if (scrollId !== "#") {
          document
            .querySelector(scrollId)
            ?.scrollIntoView({ behavior: "smooth" })
        }
      }
    },
    [trackingEvent, onClick, trackingEventKey, trackingEventValue]
  )

  const buttonClasses = useMemo(
    () =>
      classNames(
        "group inline-flex py-4 max-w-fit",
        getTextColorClass(color),
        { "opacity-50 pointer-events-none": disabled },
        { "items-center gap-8": style === "icon" },
        {
          "gap-[2px] flex-col after:content-[''] after:block after:bg-current after:h-px after:transition-[width] after:duration-300":
            style === "underlined" || style === "text",
        },
        {
          "after:w-full after:hover:w-[80%] after:focus-visible:w-[80%] items-center":
            style === "underlined",
        },
        {
          "after:w-0 after:hover:w-full after:focus-visible:w-full items-start":
            style === "text",
        },
        { uppercase: uppercase },
        className
      ),
    [color, disabled, style, uppercase, className]
  )

  const buttonContent = (
    <>
      {mobileText ? (
        <>
          <Typography
            text={mobileText}
            weight={weight}
            font="grotesk"
            color={color}
            size={textSize}
            leadingNone
            className="block md:hidden"
          />
          <Typography
            text={text}
            weight={weight}
            font="grotesk"
            color={color}
            size={textSize}
            leadingNone
            className="hidden md:block"
          />
        </>
      ) : (
        <Typography
          text={text}
          weight={weight}
          font="grotesk"
          color={color}
          size={textSize}
          leadingNone
        />
      )}
      {style === "icon" && icon && (
        <div
          className={classNames(
            getTextColorClass(color),
            "w-20 md:w-[22px] lg:w-24"
          )}
        >
          {icon}
        </div>
      )}
    </>
  )

  if (linkUrl && mobileLinkUrl) {
    return (
      <>
        <Link
          link={linkUrl}
          onClick={(e) => handleClick(e, linkUrl)}
          className={classNames("hidden md:inline-flex", buttonClasses)}
        >
          {buttonContent}
        </Link>
        <Link
          link={mobileLinkUrl}
          onClick={(e) => handleClick(e, mobileLinkUrl)}
          className={classNames("md:hidden", buttonClasses)}
        >
          {buttonContent}
        </Link>
      </>
    )
  } else if (linkUrl && !mobileLinkUrl) {
    return (
      <Link
        link={linkUrl}
        onClick={(e) => handleClick(e, linkUrl)}
        className={buttonClasses}
      >
        {buttonContent}
      </Link>
    )
  }

  return (
    <button
      type="button"
      onClick={(e) => handleClick(e)}
      className={buttonClasses}
      disabled={disabled}
    >
      {buttonContent}
    </button>
  )
}

export default TextButton
