import classNames from "classnames"
import React, { ReactNode, useCallback, useMemo } from "react"

import { Color } from "../../../../constants/V2/color"
import { trackSegmentEvent } from "../../../../utils/analytics"
import { useIsBreakpoint } from "../../../../utils/V2/screen"
import Link from "../../Link"
import ArrowIcon from "../Icons/ArrowIcon"
import Typography, { TypographySize } from "../Typography"

import {
  ACCELERATE_EXPERIMENT_EVENT_NAME,
  PLANS_EXPERIMENT_EVENT_NAME,
} from "@constants/segmentEvents"
import {
  getBorderColorClass,
  getBackgroundColorClass,
  getBackgroundHoverColorClass,
  getAccentColor,
  getTextColorClass,
  getTextHoverColorClass,
} from "@utils/V2/color"

type Props = {
  style: "solid" | "bordered"
  size: "large" | "medium" | "small"
  type?: "button" | "submit"
  color: Color
  overrideTextColor?: Color
  text: string
  fontSize?: TypographySize
  mobileText?: string
  linkUrl?: string
  mobileLinkUrl?: string
  onClick?: () => void
  transparentHover?: boolean
  hideIcon?: boolean
  icon?: ReactNode
  iconPosition?: "left" | "right"
  disabled?: boolean
  trackingEvent?: string
  trackingEventKey?: string
  trackingEventValue?: string
  className?: string
  secondaryText?: string
  hasScrollArrow?: boolean
}

const PillButton = ({
  style,
  size = "large",
  type = "button",
  color,
  overrideTextColor = getAccentColor(color),
  text,
  mobileText,
  fontSize,
  linkUrl,
  mobileLinkUrl,
  onClick,
  transparentHover = false,
  hideIcon = false,
  icon = <ArrowIcon />,
  iconPosition = "right",
  disabled = false,
  trackingEvent,
  trackingEventKey,
  trackingEventValue,
  className,
  secondaryText,
  hasScrollArrow = false,
}: Props) => {
  const isMobile = useIsBreakpoint("mobile")
  const isTablet = useIsBreakpoint("tablet")
  const sizeOverride = isMobile || isTablet ? "medium" : size

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLElement>, link?: string) => {
      if (trackingEvent) {
        // this is to support custom plans tracking event
        // TODO rework this to be more generic in future

        let event: Record<string, string> | undefined
        if (
          trackingEvent === ACCELERATE_EXPERIMENT_EVENT_NAME ||
          trackingEvent === PLANS_EXPERIMENT_EVENT_NAME
        ) {
          const urlParams = new URLSearchParams(window.location.search)
          const buuidValue = urlParams.get("buuid") || ""
          const vValue = urlParams.get("v") || ""
          const pValue = urlParams.get("p") || ""
          event = { ["buuid"]: buuidValue, ["v"]: vValue, ["p"]: pValue }
        } else {
          event =
            trackingEventKey && trackingEventValue
              ? { [trackingEventKey]: trackingEventValue }
              : undefined
        }

        trackSegmentEvent(trackingEvent, event)
      }

      if (onClick) {
        onClick()
      } else if (link && link.startsWith("#")) {
        e.preventDefault()
        const [scrollId, clickId] = link.includes("@")
          ? link.split("@")
          : [link, null]

        if (clickId) {
          document.getElementById(clickId)?.click()
        }

        if (scrollId !== "#") {
          document
            .querySelector(scrollId)
            ?.scrollIntoView({ behavior: "smooth" })
        }
      }
    },
    [trackingEvent, onClick, trackingEventKey, trackingEventValue]
  )

  const buttonClasses = useMemo(
    () =>
      classNames(
        getBorderColorClass(color),
        style === "solid"
          ? getBackgroundColorClass(color)
          : [
              "bg-transparent transition-colors duration-300",
              getBackgroundHoverColorClass(color),
              transparentHover && "hover:bg-opacity-10",
            ],
        { "opacity-50 pointer-events-none": disabled },
        "group rounded-32 border flex gap-10 items-center w-full sm:w-max justify-center",
        {
          "px-20 py-[15px] md:px-[18px] md:py-[13px] lg:px-20 lg:py-[15px]": [
            "large",
            "medium",
          ].includes(sizeOverride),
        },
        {
          "px-16 py-[9px]": size === "small",
        },
        { "flex-row-reverse": iconPosition === "left" },
        className
      ),
    [
      color,
      style,
      transparentHover,
      disabled,
      sizeOverride,
      size,
      iconPosition,
      className,
    ]
  )

  const buttonContentClasses = classNames(
    "!leading-none transition-colors duration-300",
    style === "bordered" &&
      !transparentHover &&
      getTextHoverColorClass(getAccentColor(color))
  )

  const buttonContent = (
    <>
      <div className="flex gap-10 pt-[2px]">
        {mobileText ? (
          <Typography
            text={isMobile ? mobileText : text}
            weight="medium"
            font="grotesk"
            color={overrideTextColor}
            size={
              fontSize || (size === "large" ? "body-lg-dual" : "body-md-fixed")
            }
            className={buttonContentClasses}
          />
        ) : (
          <Typography
            text={text}
            weight="medium"
            font="grotesk"
            color={overrideTextColor}
            size={
              fontSize || (size === "large" ? "body-lg-dual" : "body-md-fixed")
            }
            className={buttonContentClasses}
          />
        )}

        {secondaryText && (
          <Typography
            text={secondaryText}
            weight="medium"
            font="grotesk"
            color={overrideTextColor}
            size={
              fontSize || (size === "large" ? "body-lg-dual" : "body-md-fixed")
            }
            className={buttonContentClasses}
          />
        )}
      </div>
      {!hideIcon && (
        <div
          className={classNames(
            getTextColorClass(overrideTextColor),
            { "rotate-90 pl-[2px]": hasScrollArrow },
            hasScrollArrow && size === "small"
              ? "w-16 !pl-0"
              : "w-20 md:w-[22px] lg:w-24",
            buttonContentClasses
          )}
        >
          {icon}
        </div>
      )}
    </>
  )

  if (linkUrl && mobileLinkUrl) {
    return (
      <Link
        link={isMobile ? mobileLinkUrl : linkUrl}
        onClick={(e) => handleClick(e, isMobile ? mobileLinkUrl : linkUrl)}
        className={buttonClasses}
      >
        {buttonContent}
      </Link>
    )
  } else if (linkUrl && !mobileLinkUrl) {
    return (
      <Link
        link={linkUrl}
        onClick={(e) => handleClick(e, linkUrl)}
        className={buttonClasses}
      >
        {buttonContent}
      </Link>
    )
  }

  return (
    <button
      type={type}
      onClick={(e) => handleClick(e)}
      className={buttonClasses}
      disabled={disabled}
    >
      {buttonContent}
    </button>
  )
}

export default PillButton
